<template>
  <Modal @close="$emit('close')" classes="max-w-5xl">
    <h1 class="heading font-bold self-center">Preview Emails.</h1>
    <LoadingSpinner v-if="loading" />
    <template v-else>
      <Button primary classes="mt-8 self-center" @click.prevent="$emit('cta-click')">
        {{ ctaText }}
      </Button>
      <EmailPreview
        v-for="(preview, idx) in previews"
        :key="idx"
        :to="opportunities[idx].contactEmail"
        :subject="preview.subject"
        :body="preview.body"
      />
      <Button primary classes="mt-10 self-center" @click.prevent="$emit('cta-click')">
        {{ ctaText }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Opportunity from '@/services/opportunities'
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'

import Modal from '@/components/modals/Modal'
import LoadingSpinner from '@/components/LoadingSpinner'
import EmailPreview from '@/components/EmailPreview'
import Button from '@/components/inputs/Button'

export default {
  name: 'PreviewEmailsModal',
  components: {
    Modal,
    LoadingSpinner,
    EmailPreview,
    Button,
  },
  props: {
    ctaText: {
      type: String,
      default: 'Send',
    },
    // if a template is present, it is used instead of opportunity-specific templates
    template: {
      type: OpportunityEmailTemplate,
      required: false,
    },
    opportunities: {
      type: Array,
      required: true,
      validator: function (value) {
        // Ensure that all elements in the array are instances of Opportunity
        return value.every((opportunity) => opportunity instanceof Opportunity)
      },
    },
  },
  data() {
    return {
      loading: true,
      previews: [],
    }
  },
  async created() {
    if (this.template) {
      await this.retrievePreviewsWithTemplateOverride()
    } else {
      await this.retrievePreviewsWithOpportunityTemplates()
    }
    this.loading = false
  },
  methods: {
    async retrievePreviewsWithTemplateOverride() {
      const promises = this.opportunities.map((o) =>
        OpportunityEmailTemplate.api.preview({
          opportunityId: o.id,
          emailSubject: this.template.subject,
          emailBody: this.template.body,
        }),
      )
      this.previews = await Promise.all(promises)
    },
    async retrievePreviewsWithOpportunityTemplates() {
      const promises = this.opportunities.map((o) =>
        OpportunityEmailTemplate.api.preview({
          opportunityId: o.id,
          emailSubject: o.friendlyIntroEmailTemplate.subject,
          emailBody: o.friendlyIntroEmailTemplate.body,
        }),
      )
      this.previews = await Promise.all(promises)
    },
    handleCTA() {
      this.$emit('cta-click')
    },
  },
}
</script>
