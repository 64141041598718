<template>
  <div class="max-w-screen-xl w-full border-b border-gray-200 dark:border-gray-700">
    <nav
      class="flex space-x-2"
      :class="{ 'overflow-scroll': scroll }"
      aria-label="Tabs"
      role="tablist"
    >
      <button
        v-for="tab in tabs"
        :key="tab.id"
        type="button"
        class="py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600"
        :class="{ 'font-semibold border-blue-100 text-blue-600': tab.id === currentTabId }"
        role="tab"
        @click="$emit('select', tab)"
      >
        {{ tab.label }}
        <span
          v-if="tab.badge"
          class="ml-1 py-0.5 px-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300"
          :class="{
            'bg-blue-100 text-blue-600 dark:bg-blue-800 dark:text-white': tab.id === currentTabId,
          }"
        >
          {{ tab.badge }}
        </span>
      </button>
    </nav>
  </div>

  <div class="max-w-screen-xl w-full mt-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTabId: {
      type: [String, Number],
      default: null,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select'],
}
</script>
