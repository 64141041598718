<template>
  <div class="flex flex-col">
    <div>
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ currentPageStart }}</span>
        to
        <span class="font-medium">{{ currentPageEnd }}</span>
        of
        <span class="font-medium">{{ totalCount }}</span>
        {{ pluralItemName }}
      </p>
    </div>
    <div class="flex flex-row space-x-2">
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg pagination-button"
        :class="{
          'hover:bg-gray-100 hover:text-gray-700 cursor-pointer': hasPrevPage,
          'cursor-not-allowed': !hasPrevPage,
        }"
        @click.prevent="hasPrevPage && $emit('prev-page')"
      >
        <ArrowLeft />
        Previous
      </a>
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg pagination-button"
        :class="{
          'hover:bg-gray-100 hover:text-gray-700 cursor-pointer': hasNextPage,
          'cursor-not-allowed': !hasNextPage,
        }"
        @click.prevent="hasNextPage && $emit('next-page')"
      >
        Next
        <ArrowRight />
      </a>
    </div>
  </div>
</template>

<script>
import {
  totalPages,
  currentPageStart,
  currentPageEnd,
  hasPrevPage,
  hasNextPage,
} from '@/services/pagination'
import ArrowLeft from '@/components/icons/ArrowLeft'
import ArrowRight from '@/components/icons/ArrowRight'

export default {
  name: 'Pagination',
  components: { ArrowLeft, ArrowRight },
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    pluralItemName: {
      type: String,
      default: 'results',
    },
  },
  computed: {
    totalPages() {
      return totalPages(this.totalCount, this.pageSize)
    },
    currentPageStart() {
      return currentPageStart(this.page, this.pageSize)
    },
    currentPageEnd() {
      return currentPageEnd(this.page, this.pageSize, this.totalCount)
    },
    hasPrevPage() {
      return hasPrevPage(this.page)
    },
    hasNextPage() {
      return hasNextPage(this.page, this.totalPages)
    },
  },
}
</script>

<style scoped>
.pagination-button {
  width: 7rem;
  display: flex;
  justify-content: center;
}
</style>
