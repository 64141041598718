<template>
  <div class="flex items-center">
    <Popper v-if="opportunityHasCustomTemplate" hover placement="left-end">
      <TrashIcon @click="attemptTemplateDelete()" class="h-5 w-5 cursor-pointer" />
      <template #content>
        <div class="bg-white rounded-lg p-5 border w-48">
          Delete custom template, revert to default.
        </div>
      </template>
    </Popper>
    <PencilSquareIcon @click="show = true" class="h-5 w-5 cursor-pointer" />
    <EditEmailTemplateModal
      v-if="show"
      :template="opportunity.friendlyIntroEmailTemplate"
      @cta-click="attemptCreateOrUpdate"
      @close="show = false"
    />
  </div>
</template>

<script>
import Opportunity from '@/services/opportunities'
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'

import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { TrashIcon } from '@heroicons/vue/24/outline'
import EditEmailTemplateModal from '@/components/modals/EditEmailTemplateModal'
import { successToast } from '@/services/toastify'
import { generateRandomCode } from '@/services/utils'

export default {
  name: 'EditOpportunityEmailTemplate',
  components: { PencilSquareIcon, TrashIcon, EditEmailTemplateModal },
  props: {
    opportunity: {
      type: Opportunity,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      attemptingCreateOrUpdate: false,
    }
  },
  methods: {
    attemptCreateOrUpdate(fields) {
      if (this.attemptingCreateOrUpdate) return
      this.attemptingCreateOrUpdate = true

      const create = !this.opportunityHasCustomTemplate

      const promise = create ? this.attemptCreate(fields) : this.attemptUpdate(fields)
      promise.then(this.handleSuccess).finally(() => (this.attemptingCreateOrUpdate = false))
    },
    attemptCreate(data) {
      data.name = `${this.opportunity.contactName}, ${
        this.opportunity.salesforceGtmListName
      } _${generateRandomCode()}`
      data.isAdminTemplate = false
      return OpportunityEmailTemplate.api
        .create(data)
        .then((template) =>
          Opportunity.api.update(this.opportunity.id, { customEmailTemplateId: template.id }),
        )
        .then((opportunity) => this.$emit('update-opportunity', opportunity))
    },
    attemptUpdate(data) {
      return OpportunityEmailTemplate.api
        .update(this.opportunity.customEmailTemplate.id, data)
        .then(() => Opportunity.api.retrieve(this.opportunity.id))
        .then((opportunity) => this.$emit('update-opportunity', opportunity))
    },
    handleSuccess() {
      successToast('Template saved.')
      this.show = false
    },
    attemptTemplateDelete() {
      OpportunityEmailTemplate.api
        .delete(this.opportunity.customEmailTemplate.id)
        .then(() => Opportunity.api.retrieve(this.opportunity.id))
        .then((opportunity) => this.$emit('update-opportunity', opportunity))
        .then(() => successToast('Template deleted.'))
    },
  },
  computed: {
    opportunityHasCustomTemplate() {
      return !!this.opportunity.customEmailTemplate
    },
  },
}
</script>
