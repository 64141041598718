export function totalPages(totalCount, pageSize) {
  return Math.ceil(totalCount / pageSize)
}

export function currentPageStart(page, pageSize) {
  return page > 1 ? (page - 1) * pageSize + 1 : 1
}

export function currentPageEnd(page, pageSize, totalCount) {
  return Math.min(page > 1 ? page * pageSize : pageSize, totalCount)
}

export function hasPage(page, pageSize, totalCount) {
  const tPages = totalPages(totalCount, pageSize)
  return page >= 1 && page <= tPages
}

export function hasPrevPage(page) {
  return page > 1
}

export function hasNextPage(page, totalPages) {
  return page < totalPages
}
