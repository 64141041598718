<template>
  <div class="flex items-center">
    <Button primary :disabled="disabled" @click="step = steps.EDIT"> Edit, Preview, Send </Button>
    <EditEmailTemplateModal
      v-if="showEditModal"
      @close="step = steps.NONE"
      @cta-click="handleEditCTA"
      :template="template"
      ctaText="Preview"
    />
    <PreviewEmailsModal
      v-if="showPreviewModal"
      @close="step = steps.NONE"
      :opportunities="opportunities"
      :template="templateWithEphemeralEdits"
      @cta-click="attemptSendEmails()"
    />
  </div>
</template>

<script>
import Opportunity from '@/services/opportunities'
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'

import LoadingSpinner from '@/components/LoadingSpinner'
import Button from '@/components/inputs/Button'
import EditEmailTemplateModal from '@/components/modals/EditEmailTemplateModal'
import PreviewEmailsModal from '@/components/modals/PreviewEmailsModal'

const NONE = 'none'
const EDIT = 'edit'
const PREVIEW = 'preview'
const steps = {
  NONE,
  EDIT,
  PREVIEW,
}

export default {
  name: 'EditPreviewSendEmails',
  components: {
    LoadingSpinner,
    Button,
    EditEmailTemplateModal,
    PreviewEmailsModal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    opportunities: {
      type: Array,
      required: true,
      validator: function (value) {
        // Ensure that all elements in the array are instances of Opportunity
        return value.every((opportunity) => opportunity instanceof Opportunity)
      },
    },
    template: {
      type: OpportunityEmailTemplate,
    },
  },
  data() {
    return {
      steps,
      step: steps.NONE,
      templateWithEphemeralEdits: null,
    }
  },
  methods: {
    handleEditCTA(fields) {
      this.templateWithEphemeralEdits = new OpportunityEmailTemplate(fields)
      this.step = steps.PREVIEW
    },
    async attemptSendEmails() {
      const confirmation = confirm('Confirm action: Send Emails.')
      if (!confirmation) return

      const promises = this.opportunities.map((o) =>
        Opportunity.api.sendEmail(
          o.id,
          this.templateWithEphemeralEdits.subject,
          this.templateWithEphemeralEdits.body,
        ),
      )
      await Promise.all(promises)
      this.$emit('sent')
    },
  },
  computed: {
    showEditModal() {
      return this.step === steps.EDIT && !!this.template
    },
    showPreviewModal() {
      return this.step === steps.PREVIEW
    },
  },
}
</script>
