<template>
  <td
    class="relative border border-slate-300 break-normal px-8"
    @mouseover="show = true"
    @mouseleave="show = false"
  >
    <slot />
    <div
      class="absolute right-2 top-2 flex flex-row z-20"
      :class="{
        invisible: !show,
        visible: show,
      }"
    >
      <slot name="actions" />
    </div>
  </td>
</template>

<script>
export default {
  name: 'ActionTableCell',
  data() {
    return {
      show: false,
    }
  },
}
</script>
