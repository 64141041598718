<template>
  <div class="flex items-center">
    <PencilSquareIcon @click="show = true" class="h-5 w-5 cursor-pointer" />
    <Modal v-if="show" @close="show = false" classes="max-w-5xl">
      <h1 class="heading font-bold">Update Email Address.</h1>
      <div class="text-base text-left">
        <div>
          <a
            v-if="opportunity.contactLinkedinProfile"
            :href="opportunity.contactLinkedinProfile"
            target="_blank"
            class="text-cyan-500"
          >
            {{ opportunity.contactName }}
          </a>
          <template v-else>{{ opportunity.contactName }}</template>
        </div>
        <div>{{ opportunity.contactTitle }}</div>
        <div>{{ opportunity.contactCompanyName }}</div>
      </div>
      <form class="w-full flex items-start justify-center mt-6" @submit.prevent>
        <InputField
          v-model:value="form.email.value"
          :errors="form.email.errors"
          @blur="form.email.validate()"
          placeholder="Email address..."
          class="grow"
        />
        <Button primary class="h-fit min-w-fit ml-3" @click.prevent="attemptSetEmailAddress()">
          Set Email Address
        </Button>
      </form>
    </Modal>
  </div>
</template>

<script>
import Contact from '@/services/contacts'
import Opportunity from '@/services/opportunities'

import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import Modal from '@/components/modals/Modal'
import InputField from '@/components/inputs/InputField'
import Button from '@/components/inputs/Button'
import { EmailAddressForm } from '@/services/contacts/forms'
import { successToast } from '@/services/toastify'

export default {
  name: 'OpportunityUpdateEmailAddress',
  components: { PencilSquareIcon, Modal, InputField, Button },
  props: {
    opportunity: {
      type: Opportunity,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      form: new EmailAddressForm(),
      submitting: false,
    }
  },
  methods: {
    attemptSetEmailAddress() {
      if (this.submitting) return
      this.form.validate()
      if (!this.form.isValid) return

      const confirmation = confirm('Confirm action: Set Email Address.')
      if (!confirmation) return

      this.submitting = true

      Contact.api
        .update(this.opportunity.contactId, { email: this.form.email.value })
        .then((contact) => {
          const copy = new Opportunity(JSON.parse(JSON.stringify(this.opportunity)))
          copy.contactEmail = contact.email
          this.$emit('update-opportunity', copy)
          successToast(`Email Address updated for ${this.opportunity.contactName}.`)
          this.show = false
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {
    show() {
      this.form = new EmailAddressForm()
    },
  },
}
</script>
